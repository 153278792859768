const productCategory = [
    { id : 1, label : "Footwear", value : "footwear"},
    { id : 2, label : "Camera", value : "camera"},
    { id : 3, label : "Earphones", value : "earphones"},
    { id : 4, label : "WesternWear", value : "westernwear"},
    { id : 5, label : "Sportswear", value : "sportswear"},
    { id : 6, label : "Printers", value : "printers"},
    { id : 7, label : "Home", value : "home"},
    { id : 8, label : "Jewellery", value : "jewellery"},
    // { id : 9, label : "Speakers", value : "speakers"},
    // { id : 10, label : "Trimmers", value : "trimmers"},
    // { id : 11, label : "Televisions", value : "televisions"},
    // { id : 12, label : "Watches", value : "watches"},
    { id : 13, label : "Men", value : "men"},
    { id : 14, label : "Woman", value : "women"},
    { id : 13, label : "Kids", value : "kids"},
    { id : 14, label : "Sari", value : "sari"},
    { id : 15, label : "Jeans", value : "jeans"},
    { id : 16, label : "Topwear", value : "topwear"},
    { id : 17, label : "Buttomwear", value : "buttomwear"}
]


export default productCategory